import './App.css';
import './Card.css';
import GetList from './Getlist';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import GetCrops from './getDetails/GetCrops';
import Details from './getDetails/Details';
import AddCat from './Models/AddCat';
import Update from './Models/Update';
import UpdateCrop from './Models/UpdateCrop';
import AddCrops from './Models/AddCrops';
import AddDetails from './Models/AddDetails';
import UpdateDetails from './Models/UpdateDetails';
import Login from './Login';


function App() {
  return (
    <div className='App'>
      <h1 align='center' className='card-title'>డా || వై .యస్.ఆర్.హెచ్.యు ఉద్యాన పంటలు e-సమాచారం</h1>
      <div className='card-container'>
        <Router>
          <Switch>
            <Route path="/" exact>
              <Login />
            </Route>
            <Route path="/GetList" component={GetList} />
            <Route path="/GetCrops/:id" component={GetCrops} />
            <Route path="/Details/:id" component={Details} />
            <Route path="/AddCat/" component={AddCat} />
            <Route path="/Update/:id" component={Update} />
            <Route path="/UpdateCrop/:id/:category" component={UpdateCrop} />
            <Route path="/AddCrops/:id" component={AddCrops} />
            <Route path="/AddDetails/:id" component={AddDetails} />
            <Route path="/UpdateDetails/:id/:crop" component={UpdateDetails} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
