import React, { Component } from 'react'
import { Grid, Paper, Avatar, TextField, Button,Input } from '@material-ui/core'
import logo from './images/logoss.jpg';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        }
    }

    handleEvent = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        console.log(e.target.value);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.username=="Admin" &&  this.state.password=="Admin@123"){
            window.location.href = "http://localhost:3000/GetList";
        }else{
            const  confirmBox = window.confirm("Username and Password incorrect!!!");
            if(confirmBox===true){
                window.location.href = "http://localhost:3000";
            }
        }
       
    }
    render() {
        const paperStyle = {
            padding: 20,
            height: '66vh',
            width: 280,
            margin: "20px auto",
        }

        const gridStyle = {
            margin: '20px 0',
        }

        const avatarStyle = {
            width: 90,
            height: 90
        }
        const { username, password } = this.state;
        return (
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Paper elevation={10} style={paperStyle}>
                    <Grid align="center">
                        <Avatar alt="logo" src={logo} style={avatarStyle} />
                        <h2>Sign In</h2>
                    </Grid>
                    <Grid>
                        <form onSubmit={this.handleSubmit}>
                            <TextField 
                            label="Username" 
                            name="username"
                            placeholder="Enter Username..." 
                            value={username} 
                            onChange={this.handleEvent} 
                            fullWidth 
                            required variant="outlined" 
                            style={gridStyle} />
                            <TextField 
                            label="Password" 
                            name="password"
                            placeholder="Enter Password..." 
                            fullWidth 
                            value={password} 
                            onChange={this.handleEvent} 
                            type="password" 
                            required 
                            variant="outlined" 
                            style={gridStyle} />
                            <Button color="primary" type='submit' variant="contained" fullWidth style={gridStyle}>Sign In</Button>
                        </form>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default Login
