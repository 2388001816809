import React, { Component } from 'react';
import axios from "axios";

class UpdateDetails
    extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }
        );
    }

    handleSubmit = (e) => {
        const { id,crop } = this.props.match.params;
        e.preventDefault();
        const data = new FormData();
        var imagefile = document.querySelector('#fileInput');
        var filePdf = document.querySelector('#filePdf');
        data.append('crop', crop);
        data.append('name', this.state.name);
        data.append('description', this.state.description);
        data.append('image', imagefile.files[0]);
        data.append('attachment', filePdf.files[0]);  
        axios.put(`http://cohvrgudem.co.in:14074/api/v1/menus/${id}`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        )
            .then(
                response => {
                    if (response.status == 200) {
                        window.location.href = `http://localhost:3000/Details/${crop}`;
                    }
                })
    }

    render() {
        const { name, description, image } = this.state;
        return (
            <div className="form-style-5">
                <h3 align='center' className="form-style-5">Update Crop Information Here</h3>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-style-5">
                        <div className="form-style-5" >
                            <div className="form-style-5" >
                                <label className="form-style-5" >Name</label> <input type='text' className='form-style-5' name='name' value={name} onChange={this.handleChange} />
                                <label className="form-style-5" >Description</label> <input type='text' className='form-style-5' name='description' value={description} onChange={this.handleChange} />
                            </div>
                            <div className="form-style-5">
                                <label className="form-style-5">Upload Crop Image</label><input type="file" id="fileInput" className='form-style-5' value={image} onChange={this.handleChange} />
                                <label className="form-style-5">Upload Pdf Document</label><input type="file" id="filePdf" className='form-style-5' value={image} onChange={this.handleChange} />

                            </div>
                        </div>
                        <div className="form-style-5" >
                            <input type="submit" value="Update Crop" />
                        </div>

                    </div>

                </form>

                <div />
            </div>
        )
    }
}

export default UpdateDetails;

