import React, { Component } from 'react';
import '../form.css';
import axios from "axios";
import history from '../getDetails/history';

class AddCrops extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            category: ''
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }
        );
    }

    handleSubmit = (e) => {
        const { id } = this.props.match.params;
        console.log(id);
        e.preventDefault();
        const data = new FormData();
        var imagefile = document.querySelector('#fileInput');
        data.append('name', this.state.name);
        data.append('description', this.state.description);
        data.append('image', imagefile.files[0]);
        data.append('category', id);
        axios.post('http://cohvrgudem.co.in:14074/api/v1/crops',
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        ).then(
            response => {
                if (response.status == 200) {
                    console.log(id);
                    window.location.href = `http://localhost:3000/GetCrops/${id}`;
                }
            })
    }



    render() {
        const { name, description, image } = this.state;
        return (
            <div className="form-style-5">
                <h3 align='center' className="form-style-5">Add Crops Here</h3>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-style-5">
                        <div className="form-style-5" >
                            <div className="form-style-5" >
                                <label className="form-style-5" >Name</label> <input type='text' className='form-style-5' name='name' value={name} onChange={this.handleChange} />
                                <label className="form-style-5" >Description</label> <input type='text' className='form-style-5' name='description' value={description} onChange={this.handleChange} />
                            </div>
                            <div className="form-style-5">
                                <label className="form-style-5">Upload Category Image</label><input type="file" id="fileInput" className='form-style-5' value={image} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-style-5" >
                            <input type="submit" className="btn-Submit" value="Add Crop" />
                        </div>
                    </div>
                </form>
                <div />
            </div>
        )
    }
}

export default AddCrops;
