import React, { Component } from "react";
import axios from "axios";
import add from './adds.jpg';
import './Card.css';
import './form.css';
import history from './getDetails/history';
import Update from './Models/Update';


class GetList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
        }
    }
    componentDidMount() {
        axios.get('http://cohvrgudem.co.in:14074/api/v1/categories').then(
            response => {
                this.setState({
                    posts: response.data.response
                })
                console.log(response.data.response);
            }
        );
    }
    handleremove = (id, e) => {
        const confirmBox = window.confirm("Do you really want to delete this Crop?");
        if(confirmBox === true){
            axios.delete(`http://cohvrgudem.co.in:14074/api/v1/categories/${id}`).then(
                response => {
                    console.log(response);
                    console.log(response.data);
                    const posts = this.state.posts.filter(item => item.id !== id);
                    this.setState({ posts });
                }
            );
        }      
    }
    render() {
        const { posts } = this.state;
        return (
            <>
                {posts.map(post =>
                    <div className="card" >
                        <div key={post.id.toString()}  >
                            <a href="#" className="card__image" to='/src/GetCrops'>
                                <img src={post.image} height='150' width='250' alt='crop' />
                            </a>
                            <div className="card__body">
                                <a className="card__title" href="#">{post.name}</a>
                                <div className="form-style-5">
                                    <form>
                                        <input type="submit"  onClick={() => history.push(`/Update/${post.id}`)} value="Update"/>
                                        <input type="submit"  onClick={(e) => this.handleremove(post.id,e)} value="Delete"/>
                                        <input type="submit"  onClick={() => history.push(`/GetCrops/${post.id}`)} value="More Crops"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="card" onClick={() => history.push('/AddCat')}>
                    <div className="card__image">
                        <a href="#">
                            <img src={add} height='175' width='150' align="center" />
                        </a>
                        <div className="card__image">
                            <a href="#" className="card__title">Add category</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default GetList;


