import React, { Component } from 'react'
import axios from "axios";
import history from './history';
import add from '../adds.jpg';


class GetCrops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            id: '',
            setModal: ''
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        console.log(id);
        axios.get(`http://cohvrgudem.co.in:14074/api/v1/menus/${id}`).then(
            response => {
                this.setState({
                    posts: response.data.response,
                    id: id,
                    setModal: false
                })
                console.log(response.data.response);

            }
        );
    }

    raiseInvoiceClicked(newPageUrl) {

        window.open(newPageUrl, "_blank") //to open new page
    }
    handleremove = (id, e) => {
        const confirmBox = window.confirm("Do you really want to delete this Crop?");
        if (confirmBox === true) {
            axios.delete(`http://cohvrgudem.co.in:14074/api/v1/menus/${id}`).then(
                response => {
                    //console.log(response);
                    //console.log(response.data);
                    const posts = this.state.posts.filter(item => item.id !== id);
                    this.setState({ posts });
                }
            );
        }
    }
    render() {
        const { posts } = this.state;
        return (
            <>
                {posts.map(post =>
                    <div className="card" >
                        <div key={post.id.toString()}  >
                            <a href="#" className="card__image" to='/src/GetCrops'>
                                <img src={post.image} height='150' width='250' />
                            </a>
                            <div className="card__body">
                                <a className="card__title" href="#">{post.name}</a>
                                <div className="form-style-5">
                                    <form>
                                        <input type="submit" value="Update" onClick={() => history.push(`/UpdateDetails/${post.id}/${post.crop}`)} />
                                        <input type="submit" value="Delete" onClick={(e) => this.handleremove(post.id, e)} />
                                        <input type="submit" onClick={() => this.raiseInvoiceClicked(post.attachment)} value="View Data" />
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="card" onClick={() => history.push(`/AddDetails/${this.state.id}`)}>
                    <div className="card__image">
                        <a href="#">
                            <img src={add} height='100' width='100' align="center" />
                        </a>
                        <div className="form-style-5">
                            <a href="#" className="card__title">Add Details Crop Information</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default GetCrops;
